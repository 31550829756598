import React, { useState, useEffect } from "react";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import { MDXProvider } from "@mdx-js/react";

const CardPartner = (props) => {
  const [expandedCard, setExpandedCard] = useState(null);

  // Function to handle expansion of cards
  const expandCard = (event) => {
    const cardTitle = event.currentTarget.nextElementSibling.id;
    if (expandedCard === cardTitle) {
      setExpandedCard(null); // Collapse if already expanded
    } else {
      setExpandedCard(cardTitle); // Expand the clicked card
    }
  };

  // Check the hash in the URL on page load
  useEffect(() => {
    const checkHashOnLoad = () => {
      const hash = window.location.hash.substring(1); // Get the hash (without the #)
      if (hash) {
        setExpandedCard(hash); // Expand the card based on the hash
      }
    };

    checkHashOnLoad();

    // Handle hash change dynamically
    window.addEventListener("hashchange", checkHashOnLoad);

    return () => {
      window.removeEventListener("hashchange", checkHashOnLoad);
    };
  }, []);

  return (
    <div className="feature-expand" data-sal="slide-up" data-sal-easing="ease">
      <div className="title flex align-items-center justify-content-between" onClick={expandCard}>
        <h3>{props.content.title}</h3>
        <p className={expandedCard === props.content.slug ? "rotate" : ""} style={{
            border: '1px solid #000',
            borderWidth: '0 0 2px 2px',
            width: '10px',
            height: '10px',
            transform: 'rotate(-45deg)'
          }}></p>
      </div>
      <div
        className="summary"
        id={props.content.slug}
        style={{
          display: expandedCard === props.content.slug ? "block" : "none",
        }}
      >
        <MDXProvider>
          <MDXRenderer>{props.content.summary}</MDXRenderer>
        </MDXProvider>
        <img src={props.content.image} alt={props.content.slug} />
      </div>
    </div>
  );
};

export default CardPartner;
