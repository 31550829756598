import React from "react"
import "./featureImg.scss"
import { Link } from 'gatsby'

const featureImg = () => {
  	return (
      	<div className="position-relative feature-select">
        <img src={'/images/features-base.svg'} className="w-100"/>
        <Link to='/catalog-management' style={{top: '12%', left: '50%'}} activeClassName={"active"}>
            <img src={'/images/catalog-management-blue.svg'} className="feature"/>
		</Link>
        <Link to='/order-management' style={{top: '23%', left: '77%'}} activeClassName={"active"}>
            <img src={'/images/order-management-blue.svg'} className="feature"/>
		</Link>
        <Link to='/shipping-workflows' style={{top: '50%', left: '87%'}} activeClassName={"active"}>
            <img src={'/images/shipping-workflows-blue.svg'} className="feature"/>
		</Link>
        <Link to='/payments-automation' style={{top: '77%', left: '77%'}} activeClassName={"active"}>
            <img src={'/images/payments-automation-blue.svg'} className="feature"/>
		</Link>
        <Link to='/returns-management' style={{top: '88%', left: '50%'}} activeClassName={"active"}>
            <img src={'/images/returns-management-blue.svg'} className="feature"/>
		</Link>
        <Link to='/vendor-onboarding' style={{top: '77%', left: '23%'}} activeClassName={"active"}>
            <img src={'/images/vendor-onboarding-blue.svg'} className="feature"/>
		</Link>
        <Link to='/api-capabilities' style={{top: '50%', left: '13%'}} activeClassName={"active"}>
            <img src={'/images/api-capabilities-blue.svg'} className="feature"/>
		</Link>
        <Link to='/other-features' style={{top: '23%', left: '23%'}} activeClassName={"active"}>
            <img src={'/images/other-features-blue.svg'} className="feature"/>
		</Link>
      </div>
    )
}

export default featureImg
